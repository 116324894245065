.App {
  text-align: left;
  margin-top: 0 !important;
}
.mystyle {
  font-family: helvetica;
  font-size: 6px;
  fill: black;
}
html {
  --blue: #222222;
  --lgt-blue: #ffffff;
  --drk-blue: #eeeeee;
  --black: #000000;
}

a:hover,
a:focus {
  outline: none;
}

a:active {
  color: var(--black);
}

.language {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 2000;
}

.ant-modal-content button:hover,
.ant-modal-content button:focus {
  outline: none;
  color: #ffffff;
  background-color: #222222;
  border-color: #222222;
}

button:hover,
button:focus {
  outline: none;
  color: #000;
  background-color: #eeeeee;
  border-color: #eeeeee;
}

button:active {
  color: #fff;
  background-color: var(--drk-blue);
  border-color: var(--drk-blue);
}

input[type="text"]:hover,
input[type="text"]:focus {
  outline: none;
}

html {
  -ms-overflow-style: none;
}

#html::-webkit-scrollbar {
  display: none;
}

body ::-webkit-scrollbar {
  width: 0 !important;
}

body {
  overflow: -moz-scrollbars-none;
}

body {
  -ms-overflow-style: none;
}

/* ********** ********** TYPOGRAFIE ****************** ****************** */
html {
  font-size: 100%;
}

body {
  font-family: "Inter", Helvetica, Arial, sans-serif;
  font-size: 24px;
}

@font-face {
  font-family: "Inter" !important ;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("fonts/inter/Inter-Medium.woff2?v=3.15") format("woff2"),
    url("fonts/inter/Inter-Medium.woff?v=3.15") format("woff");
}

h1 {
  font-size: 7vw;
  font-weight: normal;
  color: #8c8c8c;

  line-height: 1.1em;
  display: inline;
}
h2 {
  font-size: 4.3vw;
  font-weight: normal;
  color: #8c8c8c;
  line-height: 1.1em;
  display: inline;
}
h3 {
  font-size: 4vw;
  font-weight: normal;
  color: #8c8c8c;
  line-height: 1.1em;
  display: inline;
}
h4 {
  font-size: 1.3em;
  font-weight: normal;
  color: #8c8c8c;
  line-height: 1.1em;
  display: inline;
}
p {
  font-size: 1em;
  font-weight: normal;
  color: #8c8c8c;
  line-height: 1.1em;
  display: inline;
  font-size: 1em;
}
h5 {
  font-size: 0.83em;
  font-weight: normal;
  color: #8c8c8c;
  line-height: 1.1em;
  display: inline;
}
.mono {
  filter: grayscale(100%);
}
.ant-modal-title {
  font-size: 20px;
}
.ant-form label {
  font-size: 16px;
}
.ant-tabs-tab {
  font-size: 16px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ffffff;
}

.ButtonSecondarySM {
  margin-top: 16px;
  border: solid 2px #ffffff;
  border-radius: 50px;
  cursor: pointer;
  background-color: #121212;
  color: #ffffff;
  font-size: 1.2em;
  max-height: 3em;
  line-height: 1;
  padding: 16px 24px;
}

/* **** >SM ****TYPOGRAFIE */
@media only screen and (max-width: 768px) {
  body {
    font-size: 20px;
  }

  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.4em;
  }
  h4 {
    font-size: 1.2em;
  }

  p {
    font-size: 1em;
  }
}
/* **** MD****TYPOGRAFIE */
@media only screen and (min-width: 768px) {
}

/* **** XL****TYPOGRAFIE */
@media only screen and (min-width: 1200px) {
}
/* **** XXL ****TYPOGRAFIE */
@media only screen and (min-width: 1600px) {
  h1 {
    font-size: 5em;
  }

  h2 {
    font-size: 72px;
  }
  h3 {
    font-size: 2.6em;
  }
}

h1.MobileHexName {
  margin-bottom: 0;
}
b {
  font-weight: normal;
  color: #ffffff;
}

.Detail {
  line-height: 0.9;
}
a.Detail:hover {
  opacity: 0.5;
}

.DetailCaption {
  line-height: 0.9;
  opacity: 0.6;
}

/* .detailDot h1 b,
.detailDot h1 {
  font-size: 40px;
  margin-bottom: 0;
  margin-left: 26px;
} */

.underlineLink {
  border-bottom: solid 2px #ffffff;
  transition: border-bottom 0.1s;
}
.underlineLink:hover {
  border-bottom: solid 2px #979797;
  transition: border-bottom 0.1s;
}

.underlineLinkContrast:hover {
  opacity: 0.6;
}

.ant-menu {
  background-color: rgba(255, 255, 255, 0);
  font-size: 20px;
}

.ant-menu-horizontal {
  border-bottom: 0px;
}

a.ant-typography,
.ant-typography a {
  color: #ffffff;
}

.ant-page-header-heading-title {
  font-weight: normal;
}

.ant-btn-primary {
  color: #000;
  background: #fff;
  border-color: #fff;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.12);
  box-shadow: 0 2px 0 rgba(255, 255, 255, 0.045);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  opacity: 0.8;
  color: #000;
  background: #fff;
}

.ant-tabs {
  font-size: 20px;
}

.detailappend {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.rows {
  overflow: hidden;
  height: 100%;
  position: fixed;
  padding: 0 56px;
  width: 100%;
}
.rowsMobile {
  /* overflow: hidden; */
  height: 100%;
  position: fixed;
  padding: 0 24px;
  width: 100%;
}

.rowsSubpage {
  padding: 0 56px;
}

.left {
  cursor: pointer;
  margin-top: 140px !important;
  padding-right: 12px;
}

.leftIntro {
  margin-top: 80px !important;
  padding-right: 12px;
}

.right {
  margin-top: 140px !important;
  padding-left: 12px;
}

.rightStart {
  margin-top: 40px !important;
  padding-left: 12px;
}

.rightIntro {
  margin-top: 80px !important;
  padding-left: 12px;
}

.fullWidth {
  width: calc(100% - 48px);
}

.spacer {
  margin-top: 4px;
}
.spacingLG {
  height: 40px;
}

.CTA {
  display: flex;
  justify-content: space-between;
}

.ButtonPrimary {
  margin-top: 16px;
  border: solid 2px #ffffff;
  border-radius: 50px;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  font-size: 24px;
  /* font-size: 1.2em; */
  max-height: 3em;
  line-height: 1;
  padding: 16px 24px;
  height: 60px;
}
.Button-16px {
  margin-top: 0;
}

.ButtonSecondarySM:hover {
  border: solid 2px #ffffff;
  background-color: #ffffff;
  color: #121212;
  opacity: 0.6;
}

.ButtonFooter {
  margin-top: 0;
  padding: 8px 16px;
  font-size: 1em;
}

.ant-checkbox-inner {
  width: 16px;
  height: 16px;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
  -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
}

.ButtonPrimary:hover:enabled {
  border: solid 2px #888888;
  color: #ffffff;
  background-color: #888888;
}

.ButtonPrimary:disabled {
  opacity: 0.4;
}
.ButtonPrimary:disabled:hover {
  opacity: 0.4;
  cursor: not-allowed;
}

.ButtonIntro {
  margin-top: 16px;
  border-radius: 50px;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  border: solid 2px #000000;
  font-size: 1.2em;
  max-height: 3em;
  line-height: 1;
  padding: 16px 24px;
}

.ButtonIntro:hover {
  opacity: 0.6;
}

.ButtonSecondary {
  border: solid 2px #ffffff;
  cursor: pointer;
  background-color: #121212;
  color: #ffffff;
  border-radius: 50px;
  font-size: 1.2em;
  max-height: 3em;
  line-height: 1;
  padding: 15px 24px;
}

.ButtonSecondary:hover {
  border: solid 2px #ffffff;
  color: #000000;
  background-color: #ffffff;
  color: #121212;
  opacity: 0.6;
}

.ButtonContrast {
  border-radius: 50px;
  cursor: pointer;
  font-size: 1.2em;
  max-height: 3em;
  line-height: 1;
  padding: 16px 24px;
}

.ButtonContrast:hover {
  opacity: 0.6;
}

.ButtonContrastPrimary {
  border: solid 2px #ffffff;
  border-radius: 50px;
  cursor: pointer;
  padding: 8px 16px;
  background-color: #ffffff;
  color: #000000;
  font-size: 0.9em;
}

.ButtonContrastPrimary:hover {
  opacity: 0.8;
}

a {
  color: #ffffff;
}

a:hover {
  color: #979797;
}

.SelectSentence {
  text-align: right;
  line-height: 1.2;
  margin-top: -3px;
  margin-left: 8px;
  width: 370px;
}

.SelectSentence3Colors {
  text-align: left;
  line-height: 1.2;
  margin-top: -3px;
  margin-left: 8px;
  width: 300px;
}
.termsWrapper {
  display: flex;
  align-items: flex-start;
}
.termsWrapper3Colors {
  display: flex;
  align-items: flex-start;
}
.ButtonFooter {
  margin-top: 0;
  padding: 8px 24px;
  font-size: 1.2em;
}

.CheckoutFooter {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  background-color: #222222 !important;
}
.FounderSentence {
  /* display: flex; */
  flex-direction: column;
}
.FounderSentence h3,
.FounderSentence p {
  margin-bottom: 0 !important;
}

.CheckoutFooterSelection .ButtonPrimary {
  margin-top: 0 !important;
  line-height: 1;
}

.rightFooter {
  text-align: right;
}
.rightFooter3Colors {
  text-align: left;
  display: flex;
  flex-direction: row;
}

.centerhorizontally {
  display: flex;
  align-items: center;
}
.footerColors {
  overflow: auto;
  white-space: nowrap;
}
.basketElementWrap {
  display: inline-block;
  color: white;
  text-align: center;
}
.basketElement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0 24px 16px;

  border-radius: 50px;
  width: 56px;
  height: 56px;
}

.blockie canvas {
  border-radius: 50px;
  vertical-align: "middle";
}
.identicon {
  vertical-align: "middle";
  /* border: solid red 1px; */
}
.topRight {
  display: flex;
  align-items: center;
}

.ETHSymbol {
  margin-right: 10px;
  font-size: 0.7em;
}

.column {
  float: left;
  width: 100%;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-color: red;
}

.itemsField {
  height: 308px;
  overflow: hidden;
}

.colorverse {
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

.card {
  background-color: #ffffff;
  padding: 8px;
  margin-right: 20px;
  margin-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-direction: column;
}
.card:hover {
  opacity: 0.8;
}
.colorTile {
  margin-right: 20px;
  margin-bottom: 20px;

  float: left;
  cursor: pointer;
}

.colorTileColor {
  width: 36px;
  height: 36px;
  border-radius: 18px;
}
.colorTileColor:hover {
  opacity: 0.7;
}

.cardPending {
  background-color: #131313;
  color: #ffffff;
  padding: 8px;
  margin-right: 20px;
  margin-bottom: 24px;
  display: flex;
  justify-content: left;
  align-items: flex-start;
  flex-direction: column;
  /* border: solid 2px #333333; */
  box-shadow: 0 0 1em rgba(255, 255, 255, 0.3);
}

.ColorDetail {
  position: fixed;
  top: 40px;
  bottom: 64px;
  left: 40px;
  right: 40px;
  padding: 48px 48px 0 48px;
  z-index: 200;
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
}

.ScrollableContainer {
  overflow-y: scroll;
  height: 100%;
}
.closeBig {
  font-size: 4vw;
  position: fixed;
  top: 96px;
  right: 96px;
  cursor: pointer;
}
.closeBig:hover {
  opacity: 0.6;
}
.cardColor {
  width: 160px;
  height: 160px;
}
.cardColorPending {
  width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardColorTest {
  width: 80px;
  height: 80px;
}

.cardInfo {
  color: #111111;
  font-size: 20px;
  padding-top: 8px;
}

.cardInfoPending {
  color: #ffffff;
  font-size: 20px;
  padding-top: 8px;
}

.cardMeta {
  font-size: 20px;
  color: #888888;
}

.MetaHexName {
  position: absolute;
  left: 16px;
  bottom: 16px;
}
.MetaDetail {
  position: absolute;
  left: 16px;
  top: 16px;
}
/* .MetaDetail:hover {
  opacity: 0.6;
} */

.cardhover .MetaDetail {
  display: none;
}

.cardhover:hover .MetaDetail {
  display: inline;
}

.MetaPrice {
  color: #979797;
  position: absolute;
  right: 16px;
  bottom: 16px;
}

.callToAction {
  font-size: 3vw;
  line-height: 1.2;
}

.CardBG {
  height: 100%;
}
.BigCardFounder {
  border: solid #131313 2px;
  background-color: #080808;
  padding: 36px;
  height: 100%;
}

.BigCard {
  background-color: #131313;
  padding: 20px;
  height: 100%;
  /* height: 50vh; */
}
.BigCardSearch {
  background-color: #131313;
  padding: 20px;
}

.basketCard {
  background-color: #131313;
  padding: 16px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  transition: background-color 0.4s;
}

.basketCard:hover {
  background-color: rgba(255, 255, 255, 0);
}

.circleCenterer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.circleCentererBasket {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 24px;
}

.landingPageCircles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 50%;
}

.basketDetails {
  margin-bottom: 0;
}
.basketHex {
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 1.2;
  margin-bottom: 0;
}
.landingPageAddress {
  display: flex;
  justify-content: left;
  align-items: center;
  line-height: 1.2;
  margin-bottom: 0;
}
.basketPrice {
  display: flex;
  justify-content: left;
  align-items: center;
}

.selectedElementBasket {
  border-radius: 100px;
  width: 72px;
  height: 72px;
  float: left;
  margin-right: 20px;
}

.selectedElementBasketLandingPage {
  border-radius: 100px;
  width: 60px;
  height: 60px;
  float: left;
  margin-right: 20px;
  margin-left: -12px;
}

.box {
  float: left;
  margin: 8px;
  border: solid 6px #000000;
  border-radius: 12px;
  width: 24px;
  height: 24px;
}
.boxSpecial {
  float: left;
  margin: 16px;
  border: solid 6px #000000;
  border-radius: 60px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7em;
  text-transform: uppercase;
}

.smallMenuColorBasket {
  float: left;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.smallMenuColorBasket2 {
  float: left;
  display: flex;
  justify-content: center;
  margin-right: 24px;
  margin-top: -10px;
  margin-left: 3px;
}
.smallMenuColorBasketRight {
  /* float: right; */
  position: absolute;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.floatnone {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 20px;
}

.menuColors {
  /* float: right; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 8px; */
  /* border: solid 6px #000000; */
  margin-top: 10px;
  margin-right: -14px;
  border-radius: 24px;
  width: 24px;
  height: 24px;
}
.two {
  /* float: right; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 8px; */
  /* border: solid 6px #000000; */
  /* margin-top: 10px; */
  margin-right: -14px;
  border-radius: 24px;
  width: 36px;
  height: 36px;
}

.box:hover {
  border: solid 6px #333333;
}

.boxTest:hover {
  border: solid 6px #333333;
}

.boxTest {
  float: left;
  margin: 8px;
  border: solid 6px #000000;
  border-radius: 12px;
  width: 24px;
  height: 24px;
}

.boxSpecial:hover {
  border: solid 6px #333333;
  cursor: pointer;
  opacity: 0.8;
}

.App {
  margin-top: 100px;
}

.detail {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0);
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #ffffff;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(255, 255, 255, 0);
  color: #ffffff;
}

.ant-menu-item a:hover {
  color: rgba(255, 255, 255, 0.5);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #ffffff;
  border-bottom: 2px solid rgba(1, 1, 1, 0);
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open:hover {
  color: #ffffff;
  border-bottom: 2px solid rgba(1, 1, 1, 0);
  opacity: 0.8;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #ffffff;
  opacity: 0.8;
}

.selectedElement,
.emptyHoverElement {
  border-radius: 100px;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  float: left;
}

.basketButton {
  border-radius: 50px;
  height: 56px;
  position: absolute;
  bottom: 20px;
  right: 24px;
}
.basketItems {
  position: absolute;
  bottom: 16px;
  left: 24px;
}
.footerMobileCheckout {
  position: fixed;
  bottom: 108px;
  left: 0;
  width: 100%;
  background-color: #2b2b2b !important;
}
/* .fixedBasket {
  position: fixed;
  left: 0;
  bottom: 64px;
  background-color: rgba(34, 34, 34, 0.8);
  width: 100vw;
} */

.fixedBasket {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 176px;
  background-color: rgba(34, 34, 34, 1);
  width: 100vw;
}

.CheckoutFooterSelection {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  /* flex-direction: row;
  background-color: #222222 !important; */
}
.flexbetween {
  display: flex;
  align-items: flex-start;
  padding: 24px;
}

.flexbetween p {
  margin-bottom: 0 !important;
  line-height: 1.3;
}

.ButtonRound {
  border: solid 2px #ffffff;
  border-radius: 100px;
  cursor: pointer;
  height: 100px;
  width: 100px;
  /* padding: 40px 40px; */
  background-color: #121212;
  color: #ffffff;
  font-size: 1em;
  line-height: 1.1;
}
.ButtonRound:hover {
  border: solid 2px #ffffff;
  color: #000000;
  background-color: #ffffff;
  color: #121212;
}

.initial {
  border: solid 2px #979797;
  margin-bottom: 16px;
}

.dotWrap {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-bottom: 16px;
}

.detailName {
  margin: 4px 0 0 0;
}
.founder {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3000;
}
.founderWrapper {
  background-color: #ffffff;
  border: solid #ffffff 8px;
  display: flex;
}
.founderColumn {
  float: left;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.founderRow {
  width: 320px;
  height: 320px;
}

.founderSmallWrapper {
  background-color: #ffffff;
  border: solid #ffffff 8px;
  display: flex;
}
.founderSmallColumn {
  float: left;
  width: 25%;
  height: 25%;
  min-height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.founderSmallRow {
  width: 160px;
  height: 160px;
}

.hoverHidden:hover {
  opacity: 0;
  transition: opacity 0.2s;
}
.hoverHidden {
  opacity: 1;
  transition: opacity 0.2s;
}

.xitemHover {
  position: absolute;
  font-size: 24px;
  right: 16px;
  top: 16px;
}

.xitem {
  position: absolute;
  font-size: 24px;
  color: #979797;
  right: 16px;
  top: 16px;
}
.xitemBasket {
  font-size: 32px;
  color: #ffffff;
  margin-right: 20px;
}
.xitemBasket:hover {
  color: #979797;
}

.xitem2 {
  font-size: 24px;
}
.xitem2:hover {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.9);
}

.selectedColorDetailWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.selectedColorDetail {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  margin-right: 40px;
  float: left;
}

.addressTest {
  display: flex;
  align-items: center;
}

.ant-page-header {
  padding: 23px 24px;
  letter-spacing: 0.1em;
}

.ant-page-header-heading-sub-title {
  font-size: 16px;

  padding-top: 2px;
}

.clear {
  clear: both;
}

.randomHoverDot {
  float: left;
}

.basketColors {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.square {
  flex: 1;
  aspect-ratio: 1;
}

.founderTest {
  background-color: #ffffff;
  padding: 8px;
  width: 256px;
  display: flex;
  flex-wrap: wrap;
}

.none {
  display: none;
  height: 1px !important;
  width: 1px !important;
}

.colorPicker {
  float: left;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
  /* border: solid #ffffff 8px !important; */
  /* padding: 40px !important; */
}

.colorPickerField {
  height: 15vh;
  cursor: pointer;
}

.colorPickerHue {
  height: 64px;
  width: 100%;
  position: relative;
  float: left;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  text-align: center;
  width: 100%;
  font-size: 8vw;
  line-height: 1.25;
}
.ant-dropdown-link {
  margin-left: 10px;
}

.ant-dropdown {
  width: 100%;
  background-color: #000000;
}

.ant-dropdown-menu {
  width: 100%;
  margin-top: 10px;
  height: 60vh;
  background-color: #000000;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: rgba(255, 255, 255) !important;
  background-color: #000000;
}

.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  color: rgba(255, 255, 255, 0.5);
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.5);
}

.rotate {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.testing {
  background-color: lightcoral;
  height: 200px;
}

.inputWrapper {
  position: relative;
  margin-top: 40px;
  width: 100%;
}

.desktopNav {
  display: initial;
  left: 50%;
  top: -20px;
  padding-top: 20px;
  margin-left: -50vw;
  /* border: solid red 1px; */
  width: 100vw;
  background-color: #000000;
}

.topBlack {
  height: 18px;
  width: 100vw;
  position: fixed;
  background-color: green;
  z-index: 10000;
}

.MobileHexNameWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.fixed {
  position: fixed !important;
}
.relative {
  position: relative !important;
}
.fullwidth {
  width: 100%;
}
.margin-bottom-zero {
  margin-bottom: 0 !important ;
}

/* ***************RESPONSIVE ****************** ************** **************** */

/* **** XS **** */
@media only screen and (max-width: 576px) {
  .hiddenMobile {
    display: none;
  }

  .hiddenDesktop {
    display: initial;
  }

  .desktopNav {
    display: none;
  }

  .xsNav {
    display: initial;
    position: fixed;
    font-size: 20px;
    text-align: right;
    right: 10px;
    top: 10px;
    padding: 18px;
  }
  .mobileNav {
    display: none;
  }

  .SelectHexName {
    right: 24px;
    bottom: 112px;
    /* width: 50vw; */
  }

  .detailDot {
    border-radius: 32px;
    width: 64px !important;
    height: 64px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .detailDot:hover {
    opacity: 0.6;
  }
  .column {
    height: 400px;
  }
  .spacerLG {
    height: 40px;
    width: 100%;
    float: left;
  }

  .selectedElement {
    width: 20vw !important;
    height: 20vw !important;
  }

  .topBlackMobile {
    width: 100vw;
    height: 77px;
    background-color: #000000;
    position: fixed;
    position: 0 0;
  }
  .topBlack {
    display: none;
  }
}
/* **** SM **** */
@media only screen and (max-width: 768px) {
  .hiddenMobile {
    display: none;
  }

  .hiddenDesktop {
    display: initial;
  }

  .desktopNav {
    display: none;
  }

  .mobileNav {
    display: initial;
    position: fixed;
    font-size: 20px;
    text-align: right;
    right: 10px;
    top: 10px;
    padding: 18px;
  }
  .xsNav {
    display: none;
  }
  .rowsSubpage {
    padding: 0 24px;
    margin-top: 72px;
  }

  .SelectHexName {
    right: 24px;
    bottom: 112px;
    /* width: 47vw; */
  }
  .intro h2 {
    display: inline;
    vertical-align: middle;
  }

  .footer {
    width: 100%;
    height: 100px;
    background-color: #222222;
    position: fixed;
    bottom: 0;
  }
  .footerCheckout {
    width: 100%;
    height: 100px;
    position: fixed;
    bottom: 0;
    background-color: #222222;
  }
  .detailDot {
    border-radius: 100px;
    width: 80px;
    height: 80px;
  }
  .ant-input-lg {
    font-size: 1em;
  }

  .spacerLG {
    height: 40px;
    width: 100%;
    float: left;
    display: block;
  }
  .spacerSM {
    height: 24px;
    width: 100%;
    clear: both;
  }
  /* .BigCardNoBG {
    margin-top: 64px;
  } */

  .BigCard {
    padding: 20px;
    margin-top: 64px;
  }
  .BigCardSearch {
    padding: 20px;
    /* margin-top: 64px; */
  }

  .FooterBasketDesktop {
    padding: 20px;
    z-index: 1;
    /* margin: 20px; */
    /* display: flex;
    align-items: center; */
  }

  .zindexbottom {
    z-index: 1;
  }
  .zindextop {
    z-index: 2;
  }
  .zindex1000 {
    z-index: 1000;
  }
  .rows {
    padding: 0 24px;
  }
  .left {
    margin-top: 72px !important;
    padding-right: 0;
  }

  .firstMobile {
    float: left;
    height: 200px;
    overflow: hidden;
    margin-top: 72px;
  }

  .ColMobile {
    height: 100%;
    overflow: scroll;
  }
  .box {
    float: left;
    margin: 8px;
    border: solid 6px #000000;
    border-radius: 24px;
    width: 32px;
    height: 32px;
  }

  .boxSpecial {
    float: left;
    margin: 16px;
    border: solid 6px #000000;
    border-radius: 60px;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7em;
    text-transform: uppercase;
  }

  .ant-tabs-tab {
    font-size: 1.2em;
    /* position: fixed;
    top: 72px; */
  }
  /* .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    position: fixed;
  } */
  .Picker {
    height: 40px;
    width: 40px;
  }
  .fullWidth {
    width: calc(100vw - 48px);
  }
  .itemsField {
    height: 100%;
  }

  .selectedElement {
    width: 20vw !important;
    height: 20vw !important;
  }
  .ButtonPrimary {
    padding: 10px 10px;
  }
  .ButtonIntro {
    padding: 10px 10px;
  }
  .ButtonSecondary {
    padding: 10px 10px;
  }
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex-center-true {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .selectedElementBasket {
    border-radius: 100px;
    width: 56px;
    height: 56px;
    float: left;
    margin-right: 20px;
  }

  .square {
    min-height: 15vw;
    max-height: 20vw;
  }
  .SelectSentence {
    text-align: left;
    line-height: 1.2;
    margin-top: -3px;
    margin-left: 8px;
    width: 200px;
  }
  .ColorDetail {
    top: 16px;
    bottom: 16px;
    right: 16px;
    left: 16px;
    padding: 24px 24px 0 24px;
  }
  .closeBig {
    font-size: 32px;
    position: fixed;
    top: 40px;
    right: 40px;
    cursor: pointer;
  }
  .TokenID {
    position: fixed;
    bottom: 36px;
  }
  .ant-tabs {
    font-size: 14px;
  }
  .tabfontsize {
    font-size: 20px;
  }
}
/* **** MD **** */
@media only screen and (min-width: 768px) {
  .hiddenMobile {
    display: initial;
  }
  .hiddenDesktop {
    display: none;
  }
  .desktopNav {
    display: initial;
  }

  .ant-menu-horizontal {
    border-bottom: 0px;
    margin-top: 60px;
  }

  .mobileNav {
    display: none;
  }
  .xsNav {
    display: none;
  }
  .rowsSubpage {
    padding: 0 56px;
    margin-top: 140px;
  }

  .SelectHexName {
    position: absolute;
    right: 24px;
    bottom: 112px;
    /* width: 26vw; */
  }
  .intro h1 {
    display: inline;
    vertical-align: middle;
  }
  .intro h2 {
    display: inline;
    vertical-align: middle;
  }
  .detailDot {
    border-radius: 100px;
    width: 160px;
    height: 160px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .SelectCTA {
    position: absolute;
    right: 20px;
    bottom: 4px;
  }
  .SelectCTAbasket {
    position: absolute;
  }

  .disabledButton {
    opacity: 0.3;
  }
  .ant-input-lg {
    padding: 6.5px 11px;
    font-size: 2vw;
  }
  .column {
    height: 100%;
  }
  .spacerLG {
    height: 48px;
    width: 100%;
  }
  .spacerSM {
    height: 24px;
    width: 100%;
  }
  .spacerXS {
    height: 8px;
    width: 100%;
  }
  .Picker {
    height: 20px;
    width: 20px;
  }
  .BigCard {
    min-height: 172px;
  }

  .footer {
    width: 100%;
    height: 100px;
    background-color: #222222;
    position: fixed;
    bottom: 0;
  }

  .footerBasket {
    width: 100%;
    height: 180px;
    background-color: #222222;
    position: fixed;
    bottom: 0;
    z-index: 2;
    padding: 20px;
  }
  .square {
    min-height: 16vw;
    max-height: 18vw;
  }
  .TokenID {
    position: fixed;
    bottom: 96px;
  }
  .ant-tabs {
    font-size: 20px;
  }
}
/* **** LG **** */
@media only screen and (min-width: 992px) {
  .spacerLG {
    height: 64px;
    width: 100%;
  }
  .spacerSM {
    height: 24px;
    width: 100%;
  }
  .square {
    min-height: 10vw;
    max-height: 20vw;
  }
  .footerBasket {
    width: 100%;
    height: 176px;
    background-color: #222222;
    position: fixed;
    bottom: 0;
    z-index: 2;
    padding: 20px;
  }
}
/* **** XL**** */
@media only screen and (min-width: 1200px) {
  .ant-menu-horizontal {
    margin-top: 18px;
  }
  .rowsSubpage {
    padding: 0 56px;
    margin-top: 100px;
  }
  .BigCard {
    min-height: 188px;
  }
}
/* **** XXL **** */
@media only screen and (min-width: 1600px) {
  /* .SelectHexName {
    width: 400px;
  } */
  .spacerLG {
    height: 72px;
    width: 100%;
  }
  .BigCard {
    min-height: 196px;
  }
}

.hidden {
  display: none !important;
}
.left-zero {
  left: 0;
}

.padding-16 {
  padding: 0 16px;
}

.LinkBottom {
  font-size: 0.7em;
  margin: 0 12px !important;
  color: #979797;
  z-index: 10;
}

.LinkBottom:hover,
.LinkBottom:active {
  color: #ffffff;
}

.noscroll {
  overflow: -moz-hidden-unscrollable;
  overflow: hidden;
  scrollbar-color: black;
  scrollbar-darkshadow-color: black;
}

.xnoscroll {
  overflow: -moz-hidden-unscrollable;
  overflow-x: hidden;
  scrollbar-color: red;
  scrollbar-darkshadow-color: black;
}
.LogoDiscord {
  opacity: 0.6;
  transition: opacity 0.3s;
  margin-right: 4px;
}
#LogoDiscord:hover .LogoDiscord {
  opacity: 1;
  transition: opacity 0.3s;
}

/* ******************* FIREWORKS ************************** */
.firework {
  position: absolute;
}
.explosion {
  position: absolute;
  left: -2px;
  bottom: 0;
  width: 4px;
  height: 80px;
  transform-origin: 50% 100%;
  /* background-color: rgba(0,0,0,.2); */
  overflow: hidden;
}
.explosion:nth-child(1) {
  transform: rotate(0deg) translateY(-15px);
}
.explosion:nth-child(2) {
  transform: rotate(30deg) translateY(-15px);
}
.explosion:nth-child(3) {
  transform: rotate(60deg) translateY(-15px);
}
.explosion:nth-child(4) {
  transform: rotate(90deg) translateY(-15px);
}
.explosion:nth-child(5) {
  transform: rotate(120deg) translateY(-15px);
}
.explosion:nth-child(6) {
  transform: rotate(150deg) translateY(-15px);
}
.explosion:nth-child(7) {
  transform: rotate(180deg) translateY(-15px);
}
.explosion:nth-child(8) {
  transform: rotate(210deg) translateY(-15px);
}
.explosion:nth-child(9) {
  transform: rotate(240deg) translateY(-15px);
}
.explosion:nth-child(10) {
  transform: rotate(270deg) translateY(-15px);
}
.explosion:nth-child(11) {
  transform: rotate(300deg) translateY(-15px);
}
.explosion:nth-child(12) {
  transform: rotate(330deg) translateY(-15px);
}

.explosion::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 40px;
  background-color: #f5cf52;
}
@keyframes explosion {
  0% {
    top: 100%;
  }
  33%,
  100% {
    top: -50%;
  }
}

#firework1 {
  left: 50%;
  top: 50%;
  transform: scale(1);
}
#firework1 .explosion::before {
  animation: explosion 2s ease-in-out;
  animation-iteration-count: 3;
}

#firework2 {
  left: 40%;
  top: 45%;
  transform: scale(0.7);
}
#firework2 .explosion::before {
  animation: explosion 2s 0.6s ease-in-out;
  animation-iteration-count: 3;
}

#firework3 {
  left: 60%;
  top: 65%;
  transform: scale(0.4);
}
#firework3 .explosion::before {
  animation: explosion 2s 0.4s ease-in-out;
}

p.start {
  line-height: 0.9em !important;
  margin-block-start: 0 !important;
  margin-block-end: 0;
  color: red !important;
}
.smallLink {
  color: #979797;
}
.smallLink:hover {
  color: #ffffff;
}
